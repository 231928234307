// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arenda-js": () => import("./../../../src/pages/arenda.js" /* webpackChunkName: "component---src-pages-arenda-js" */),
  "component---src-pages-bani-iz-rublennogo-brevna-js": () => import("./../../../src/pages/bani-iz-rublennogo-brevna.js" /* webpackChunkName: "component---src-pages-bani-iz-rublennogo-brevna-js" */),
  "component---src-pages-beamhouse-js": () => import("./../../../src/pages/beamhouse.js" /* webpackChunkName: "component---src-pages-beamhouse-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-fundament-js": () => import("./../../../src/pages/fundament.js" /* webpackChunkName: "component---src-pages-fundament-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-karkasnoe-stroitelstvo-js": () => import("./../../../src/pages/karkasnoe-stroitelstvo.js" /* webpackChunkName: "component---src-pages-karkasnoe-stroitelstvo-js" */),
  "component---src-pages-kirpichnie-doma-js": () => import("./../../../src/pages/kirpichnie-doma.js" /* webpackChunkName: "component---src-pages-kirpichnie-doma-js" */),
  "component---src-pages-kovka-js": () => import("./../../../src/pages/kovka.js" /* webpackChunkName: "component---src-pages-kovka-js" */),
  "component---src-pages-proektirovanie-js": () => import("./../../../src/pages/proektirovanie.js" /* webpackChunkName: "component---src-pages-proektirovanie-js" */),
  "component---src-pages-remont-krovli-js": () => import("./../../../src/pages/remont-krovli.js" /* webpackChunkName: "component---src-pages-remont-krovli-js" */),
  "component---src-pages-stroitelstvo-domov-js": () => import("./../../../src/pages/stroitelstvo-domov.js" /* webpackChunkName: "component---src-pages-stroitelstvo-domov-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-woodhouse-js": () => import("./../../../src/pages/woodhouse.js" /* webpackChunkName: "component---src-pages-woodhouse-js" */)
}

